import { FC } from 'react';
import Dialog from '@/components/Dialog/Dialog';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';
import { Checklist, useGetOrganizationChecklistsOrgId } from '@/api/generated';
import { useOrganization } from '@/hooks/useOrganization';
import { ButtonBase, CircularProgress, Stack } from '@mui/material';
import DialogHeader from '@/components/Dialog/DialogHeader';
import { useTranslation } from 'react-i18next';

interface ChecklistSelectDialogProps {
  isOpened: boolean;
  excludedIds?: string[];
  onClose: (checklist?: Checklist) => void;
}

const ChecklistSelectDialog: FC<ChecklistSelectDialogProps> = ({ isOpened, excludedIds = [], onClose }) => {
  const { t } = useTranslation('common');
  const { organization } = useOrganization();

  const { data: checklists, isLoading } = useGetOrganizationChecklistsOrgId(organization.id, {
    query: { enabled: isOpened },
  });

  return (
    <Dialog open={isOpened} onClose={() => onClose()}>
      <DialogHeader title={t('selectChecklistDialog.title')} />
      <DialogCloseButton onClick={onClose} />

      {isLoading && <CircularProgress size={20} />}
      <Stack sx={{ gap: 1, alignItems: 'flex-start', height: 300, overflow: 'auto', scrollbarWidth: 'thin' }}>
        {checklists
          ?.filter(checklist => !excludedIds.includes(checklist._id!))
          .map(checklist => (
            <ButtonBase key={checklist._id} sx={{ px: 2, py: 0.5, borderRadius: 1 }} onClick={() => onClose(checklist)}>
              {checklist.name}
            </ButtonBase>
          ))}
      </Stack>
    </Dialog>
  );
};

export default ChecklistSelectDialog;
