import { FC } from 'react';
import { Box, Button, CircularProgress, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useKnowledge from '@/views/Knowledge/useKnowledge';
import { TABS } from '@/views/Knowledge/components/consts';
import { Question } from '@/api/generated';

interface InitialChatScreenProps {
  suggestions?: Question[];
  isSuggestionsLoading: boolean;
  setTabValue: (tab: TABS) => void;
  onSuggestionSelect: (suggestionText: string) => void;
}

const InitialChatScreen: FC<InitialChatScreenProps> = ({
  suggestions,
  isSuggestionsLoading,
  setTabValue,
  onSuggestionSelect,
}) => {
  const { currentUser, uploadedFiles, isUploadFilesFetching } = useKnowledge();
  const { palette, spacing, typography } = useTheme();
  const { t } = useTranslation('common');
  const commonStyles = {
    fontSize: typography.body2.fontSize,
    color: palette.grey[800],
  };

  return (
    <Stack gap={4} pb={6}>
      <Stack gap={1}>
        <Typography sx={commonStyles}>{t('knowledge.introductionTexts.user', { firstName: currentUser?.firstName })}</Typography>
        <Typography sx={commonStyles}>{t('knowledge.introductionTexts.text')}</Typography>
        <Typography sx={commonStyles}>{t('knowledge.introductionTexts.text2')}</Typography>
        <Typography sx={commonStyles}>{t('knowledge.introductionTexts.text3')}</Typography>
        <Typography sx={commonStyles}>{t('knowledge.introductionTexts.text4')}</Typography>
        {!isUploadFilesFetching && !uploadedFiles?.length && (
          <Button
            variant="contained"
            color="accent"
            sx={{
              fontSize: 'body2.fontSize',
              width: 'fit-content',
              alignSelf: 'center',
              boxShadow: 0,
              '&:hover': { boxShadow: 0 },
            }}
            onClick={() => setTabValue(TABS.KNOWLEDGE_BASE)}
          >
            {t('knowledge.introductionTexts.startUploadButton')}
          </Button>
        )}
      </Stack>

      {isSuggestionsLoading ? (
        <Box sx={{ height: 160, position: 'relative' }}>
          <CircularProgress
            size={50}
            sx={{ position: 'absolute', left: '50%', top: '25%', transform: 'translate(-50%, -25%)' }}
          />
        </Box>
      ) : (
        <List sx={{ display: 'flex', flexDirection: 'row', gap: 1.75 }}>
          {suggestions?.map(({ text }) => (
            <ListItem
              key={text}
              sx={{
                border: `1px solid ${palette.primary.main}`,
                borderRadius: 2,
                p: 0,
                justifyContent: 'center',
                '&:hover': { backgroundColor: palette.primary.main },
              }}
            >
              <Button
                variant="text"
                sx={{
                  p: spacing(5, 2, 5, 1.25),
                  width: '100%',
                  textTransform: 'none',
                }}
                onClick={() => onSuggestionSelect(text)}
              >
                <Typography
                  sx={{
                    color: palette.grey[800],
                    fontSize: typography.body2.fontSize,
                    fontWeight: typography.fontWeightRegular,
                    maxWidth: 105,
                  }}
                >
                  {text}
                </Typography>
              </Button>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};

export default InitialChatScreen;
