import { ProjectFull, ProjectResponse, User } from '@/api/generated';

type Params<T extends ProjectFull | ProjectResponse> = {
  user: User;
  project: T;
};

export const addUserToProjectCollaborationList = <T extends ProjectFull | ProjectResponse>({ user, project }: Params<T>): T => ({
  ...project,
  collaborate_with: [...(project?.collaborate_with || []), user],
});

export const removeUserFromProjectCollaborationList = <T extends ProjectFull | ProjectResponse>({
  user: userToRemove,
  project,
}: Params<T>): T => ({
  ...project,
  collaborate_with: (project?.collaborate_with || []).filter(user => user._id !== userToRemove._id),
});
