import { ActionType, LearningCenterItemType } from '@/api/generated';

export const ICON_TYPE = {
  [LearningCenterItemType.youtube]: 'play',
  [LearningCenterItemType.web_page]: 'reading',
  [ActionType.new_takeoff]: 'gradiantCountAndMeasureIcon',
  [ActionType.open_upload_files_modal]: '',
  [ActionType.continue_takeoff]: 'gradiantCountAndMeasureIcon',
  [ActionType.open_create_page]: 'gradiantCreate',
  [ActionType.open_edit_project]: '',
  [ActionType.share_project]: '',
  [ActionType.new_create_page]: 'gradiantCreate',
  [ActionType.open_orgKB]: 'gradiantKB',
  [ActionType.run_checklist]: '',
  [ActionType.open_doublecheck]: 'gradiantDoubleCheck',
  [ActionType.run_compare]: 'gradiantCompare',
  [ActionType.view_document]: 'gradiantCompare',
};
