import { Components, Theme } from '@mui/material/styles';

const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: theme.shape.borderRadius * 2,
      '&:active': { boxShadow: 'none' },
    }),
    startIcon: { marginLeft: 0, marginRight: 4 },
    endIcon: { marginRight: 0, marginLeft: 20 },
    disabled: ({ theme }) => ({
      backgroundColor: theme.palette.grey['300'],
      color: theme.palette.grey['800'],
    }),
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(1, 2),
    }),
  },
  variants: [
    {
      props: { color: 'primary', variant: 'contained' },
      style: ({ theme }) => ({
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: theme.shadows[5],
        },
      }),
    },
    {
      props: { color: 'primary', variant: 'outlined' },
      style: ({ theme }) => ({
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      }),
    },
    {
      props: { size: 'xsmall' },
      style: ({ theme }) => ({
        fontSize: theme.typography.body3.fontSize,
        paddingTop: 0,
        paddingBottom: 0,
      }),
    },
    {
      props: { color: 'secondary' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
          boxShadow: 'none',
        },
      }),
    },
    {
      props: { color: 'accent' },
      style: ({ theme }) => ({
        color: theme.palette.accent.contrastText,
        boxShadow: theme.shadows[1],
      }),
    },
  ],
};
export default MuiButton;
