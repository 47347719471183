import { Box, Button, InputAdornment, List, ListItem, Stack, useTheme } from '@mui/material';
import TextInput from '@/components/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import Icon from '@/components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Memo,
  MemoCreate,
  MemoUpdate,
  useCreateOrganizationMemo,
  useDeleteOrganizationMemo,
  useGetOrganizationMemo,
  useGetOrganizationMemos,
  useUpdateOrganizationMemo,
} from '@/api/generated';
import useKnowledge from '@/views/Knowledge/useKnowledge';
import DocumentDelete from '@/components/DocumentDelete/DocumentDelete';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import MemoForm from '@/views/Knowledge/components/MemoForm';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useOrganization } from '@/hooks/useOrganization';

export interface FormCreateOrUpdateMemo {
  title: string;
  content: string;
  searchMemo?: string;
}

const validationSchemaCreateMemo = z.object({ title: z.string(), content: z.string() }).required();

const Memos = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    reset,
    setValue,
  } = useForm<FormCreateOrUpdateMemo>({
    resolver: zodResolver(validationSchemaCreateMemo),
    defaultValues: { title: '', content: '' },
  });
  const queryClient = useQueryClient();
  const { organization } = useOrganization();
  const { isModified, setIsModified, isActive } = useKnowledge();
  const { data: memos, queryKey } = useGetOrganizationMemos(organization.slug);
  const { mutateAsync: createMemoMutation } = useCreateOrganizationMemo();
  const { mutateAsync: updateMemoMutation } = useUpdateOrganizationMemo();
  const { mutateAsync: deleteMemoMutation } = useDeleteOrganizationMemo();
  const { palette, spacing } = useTheme();
  const { t } = useTranslation('common');
  const [disabled, setDisabled] = useState(false);
  const [memoId, setMemoId] = useState<string | null>(null);
  const { showConfirmDialog } = useConfirmDialog();

  const title = watch('title');
  const content = watch('content');
  const searchMemo = watch('searchMemo');

  const { data: memo } = useGetOrganizationMemo(organization.slug, memoId!, {
    query: { enabled: !!memoId },
  });

  useEffect(() => {
    setDisabled(!!title && !!content);
  }, [title, content]);

  useEffect(() => {
    if (!memo) return;

    setValue('title', memo.title);
    setValue('content', memo.content);
  }, [memo]);

  const handleReset = () =>
    reset({
      title: '',
      content: '',
    });

  useEffect(() => {
    if (!isActive) {
      handleReset();
      setMemoId('');
    }
  }, [isActive]);

  const handleCreateMemo = async (data: MemoCreate) => {
    await createMemoMutation(
      { slug: organization.slug, data },
      {
        onSuccess: (createdMemo: Memo) => {
          setIsModified(false);
          queryClient.setQueryData<Memo[]>(queryKey, cacheMemos => {
            if (!cacheMemos) return;
            return [...cacheMemos, createdMemo];
          });
          setMemoId(createdMemo.id!);
          enqueueSnackbar(t('knowledge.memos.successToasts.createMemo'));
        },
        onError: () =>
          enqueueSnackbar(t('knowledge.memos.failedToasts.createMemo'), {
            variant: 'error',
          }),
      },
    );
  };

  const handleUpdateMemo = async (data: MemoUpdate) => {
    if (!memoId) return;

    await updateMemoMutation(
      { slug: organization.slug, memoId, data },
      {
        onSuccess: (updatedMemo: Memo) => {
          if (!memos) return;
          setIsModified(false);
          queryClient.setQueryData<Memo[]>(
            queryKey,
            cacheMemos => cacheMemos?.map(cacheMemo => (cacheMemo.id === updatedMemo.id ? updatedMemo : cacheMemo)),
          );
          setMemoId(null);
          enqueueSnackbar(t('knowledge.memos.successToasts.updateMemo'));
        },
        onError: () =>
          enqueueSnackbar(t('knowledge.memos.failedToasts.updateMemo'), {
            variant: 'error',
          }),
      },
    );
  };

  const addNewMemo = async () => {
    if (!isModified) {
      handleReset();
      setMemoId(null);
      return;
    }

    const result = await showConfirmDialog({
      title: t('knowledge.memos.discardModal.title'),
      confirm: t('knowledge.memos.discardModal.discard'),
      cancel: t('knowledge.memos.discardModal.cancel'),
    });

    if (result) {
      handleReset();
      setMemoId(null);
      setIsModified(false);
    }
  };

  const displayedMemos = useMemo(
    () =>
      memos
        ?.sort((a, b) => new Date(b?.last_modified ?? '').getTime() - new Date(a?.last_modified ?? '').getTime())
        ?.filter(({ title: memoTitle }) => memoTitle.toLowerCase().includes(searchMemo?.toLowerCase() ?? '')),
    [memos, searchMemo],
  );

  const handleDelete = async (memoIdToDelete: string) => {
    await deleteMemoMutation(
      { slug: organization.slug, memoId: memoIdToDelete },
      {
        onSuccess: () => {
          if (!memos) return;
          queryClient.setQueryData<Memo[]>(
            queryKey,
            cacheMemos => cacheMemos?.filter(cacheMemo => cacheMemo.id !== memoIdToDelete),
          );
          if (memoId === memoIdToDelete) setMemoId(null);
          enqueueSnackbar(t('knowledge.memos.successToasts.deleteMemo'));
        },
        onError: err => {
          const error = err as unknown as AxiosError;
          if (error?.response?.status === 403) {
            enqueueSnackbar(t('knowledge.memos.unAuthorizedToast'), {
              variant: 'error',
              autoHideDuration: 4000,
            });
          }
        },
      },
    );
    handleReset();
  };

  const handleMemoId = async (nextId: string) => {
    const result = isModified
      ? await showConfirmDialog({
          title: t('knowledge.memos.discardModal.title'),
          confirm: t('knowledge.memos.discardModal.discard'),
          cancel: t('knowledge.memos.discardModal.cancel'),
        })
      : true;

    if (result) {
      setMemoId(nextId);
      setIsModified(false);
    }
  };

  return (
    <Stack direction="row" spacing={2} sx={{ height: '100%' }}>
      <Stack gap={1} sx={{ boxShadow: 1, borderRadius: 1, height: '100%', mt: 1 }}>
        <Box sx={{ borderBottom: `1px solid ${palette.grey[100]}` }}>
          <Button
            title={t('knowledge.memos.leftSide.button')}
            onClick={addNewMemo}
            sx={{
              color: palette.grey[800],
              width: '100%',
              justifyContent: 'flex-start',
              fontSize: 'body2.fontSize',
              px: 1,
              py: 0.75,
              fontWeight: 'fontWeightRegular',
              borderRadius: 'unset',
              '& .MuiButton-icon': { mr: 1.25, svg: { fontSize: 'base.fontSize' } },
              '&:hover': { backgroundColor: palette.primary.main },
            }}
            startIcon={<Icon sx={{ color: palette.darkPurple.dark }} name="plus" fontSize="inherit" />}
          >
            {t('knowledge.memos.leftSide.addNew')}
          </Button>
          <Stack component="form">
            <TextInput
              sx={{
                '& .MuiInputBase-root': {
                  border: '1px solid transparent',
                  '&:hover': { border: `1px solid ${palette.grey[400]}` },
                  backgroundColor: 'transparent',
                  px: 1,
                  py: 0.75,
                  '& .MuiInputAdornment-root': {
                    mr: 1.25,
                  },
                  '& .MuiInputBase-input': {
                    p: 0,
                    fontSize: 'body2.fontSize',
                    '&::placeholder': { color: palette.grey[800] },
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ color: palette.darkPurple.dark }} position="start">
                    <Icon name="search" fontSize="small" />
                  </InputAdornment>
                ),
              }}
              control={control}
              placeholder={t('knowledge.memos.leftSide.searchPlaceholder')}
              name="searchMemo"
              id="searchMemos"
            />
          </Stack>
        </Box>
        <List
          sx={{
            p: 0,
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            mb: 0.125,
          }}
        >
          {displayedMemos?.map(({ title: memoTitle, id }) => (
            <ListItem
              key={id}
              sx={{
                p: spacing(0, 1, 0, 0),
                '&:hover': { backgroundColor: palette.primary.main, '& .delete-button': { opacity: 1 } },
                '& .delete-button': { opacity: 0 },
                backgroundColor: memoId === id ? palette.primary.main : 'transparent',
              }}
            >
              <Button
                onClick={() => handleMemoId(id)}
                sx={{
                  color: palette.grey[800],
                  width: '100%',
                  justifyContent: 'flex-start',
                  fontSize: 'body2.fontSize',
                  px: 1,
                  py: 0.75,
                  fontWeight: 'fontWeightRegular',
                  '& .MuiButton-icon': { mr: 1.25, svg: { fontSize: 'base.fontSize' } },
                }}
                startIcon={<Icon sx={{ color: palette.darkPurple.dark }} name="file" />}
              >
                {memoTitle}
              </Button>
              <DocumentDelete
                sx={{ fontSize: 'body3.fontSize', '&:hover': { boxShadow: 0, backgroundColor: palette.darkPurple.main } }}
                title={t('knowledge.memos.deleteModal.description')}
                onDelete={() => handleDelete(id)}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
      <MemoForm
        onSubmit={!memoId ? handleSubmit(handleCreateMemo) : handleSubmit(handleUpdateMemo)}
        control={control}
        isSubmitting={isSubmitting}
        onChange={() => setIsModified(true)}
        disabled={disabled}
      />
    </Stack>
  );
};

export default Memos;
