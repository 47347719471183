import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import {
  DocumentMetadata,
  Thread,
  ThreadMetadata,
  useBatchDeleteDocuments,
  useDeleteThread,
  useGetAllThreads,
  useGetProjectsId,
  useGetThreadById,
  User,
  useUploadFileWithVdb,
} from '@/api/generated';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useOrganization } from '@/hooks/useOrganization';
import { ROUTER_IDS } from '@/services/linker';
import useRouteId from '@/hooks/useRouteId';

interface KnowledgeContext {
  threads?: ThreadMetadata[];
  currentUser?: User;
  uploadFile: (file: File) => Promise<DocumentMetadata>;
  isUploadFilesFetching: boolean;
  threadsRefetch: () => Promise<unknown>;
  setCurrentThreadId: (currentThreadId: string) => void;
  currentThread?: Thread;
  currentThreadId?: string;
  isThreadLoading: boolean;
  isLoadingUpload: boolean;
  deleteThread: (threadId: string) => Promise<ThreadMetadata[]>;
  isDeleteThreadPending: boolean;
  uploadedFiles?: DocumentMetadata[];
  deleteDocument: (documentId: string) => Promise<unknown>;
  isModified: boolean;
  setIsModified: (value: boolean) => void;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  setLoadingUpload: (isLoadingUpload: boolean) => void;
  uploadedFilesRefetch: () => void;
}
interface KnowledgeProviderProps {
  children: ReactNode;
}

const Context = createContext<KnowledgeContext | null>(null);

const useKnowledge = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useKnowledge must be used within a KnowledgeProvider');
  }
  return context;
};

export const KnowledgeProvider: FC<KnowledgeProviderProps> = ({ children }) => {
  const { currentUser } = useAuth();
  const { organization } = useOrganization();
  const { t } = useTranslation('common');
  const [currentThreadId, setCurrentThreadId] = useState('');
  const [isLoadingUpload, setLoadingUpload] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const routeId = useRouteId();
  const isOrgKbActive = routeId === ROUTER_IDS.ORG_KB;

  const {
    data: knowledgeBase,
    isFetching: isUploadFilesFetching,
    refetch: uploadedFilesRefetch,
  } = useGetProjectsId(organization.slug, {
    query: { enabled: isActive || isOrgKbActive },
  });
  const { data: threads, refetch: threadsRefetch } = useGetAllThreads(organization.slug, {
    query: { enabled: isActive || isOrgKbActive },
  });

  const { data: currentThread, isLoading: isThreadLoading } = useGetThreadById(organization.slug, currentThreadId);

  const { mutateAsync: uploadFileMutation } = useUploadFileWithVdb({ mutation: { retry: true, retryDelay: 500 } });
  const { mutateAsync: deleteDocumentMutation } = useBatchDeleteDocuments();
  const { mutateAsync: deleteThreadMutation, isPending: isDeleteThreadPending } = useDeleteThread();

  const deleteThread = (threadId: string) =>
    deleteThreadMutation(
      { slug: organization.slug, threadId },
      { onSuccess: () => threadsRefetch(), onError: err => console.error(err) },
    );

  const deleteDocument = (documentId: string) =>
    deleteDocumentMutation(
      { slug: organization.slug, data: [documentId] },
      {
        onSuccess: () => {
          uploadedFilesRefetch();
          enqueueSnackbar(t('knowledge.deleteSucceed'));
        },
        onError: () => {
          enqueueSnackbar(t('knowledge.deleteFailed'), { variant: 'error' });
        },
      },
    );

  const uploadFile = (file: File) => {
    setLoadingUpload(true);
    return uploadFileMutation(
      {
        slug: organization.slug,
        data: { file },
      },
      {
        onSuccess: () => {
          setLoadingUpload(false);
          uploadedFilesRefetch();
          enqueueSnackbar(t('knowledge.uploadSucceed'));
        },
        onError: () => {
          setLoadingUpload(false);
          enqueueSnackbar(t('knowledge.uploadFailed'), { variant: 'error' });
        },
      },
    );
  };

  return (
    <Context.Provider
      value={{
        currentUser,
        threads,
        uploadFile,
        threadsRefetch,
        setCurrentThreadId,
        currentThread,
        currentThreadId,
        isThreadLoading,
        isLoadingUpload,
        setLoadingUpload,
        deleteThread,
        isDeleteThreadPending,
        uploadedFiles: knowledgeBase?.documents ?? [],
        isUploadFilesFetching,
        deleteDocument,
        isModified,
        setIsModified,
        setIsActive,
        isActive: isActive || isOrgKbActive,
        uploadedFilesRefetch,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default useKnowledge;
