import { MutableRefObject, useCallback, useRef, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useQueryClient } from '@tanstack/react-query';
import { CreateHandler, DeleteHandler, MoveHandler, NodeApi, RenameHandler } from 'react-arborist';
import { enqueueSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  DocumentVersionNode,
  ImportDriveFilesFn,
  ImportParams,
  ProjectFormValue,
} from '@/views/Projects/components/ProjectFormDialog/types';
import { STATUS } from '@/utils/enums';
import { format } from 'date-fns/format';
import { updateProjectCache } from '@/utils/updateProjectCache';
import {
  AutodeskFile,
  DocumentMetadata,
  FileSystem,
  PageType,
  ProjectFull,
  useAutomatePageHook,
  useConvertPdfToPagesHook,
  useCreateFolderHook,
  useDeleteFileHook,
  useDeleteFolderHook,
  useImportAutodeskFileHook,
  useImportDocumentHook,
  useImportMicrosoftFileHook,
  useUpdateFilesystemBatchHook,
  useUploadFileWithVdbHook,
} from '@/api/generated';
import { useDeleteDocuments } from '@/hooks/useDeleteDocuments';
import { useRenameDocument } from '@/hooks/useRenameDocument';
import { constructFileSystemTree } from '@/utils/constructFileSystemTree';
import { convertFilSystemTreeToServerFileSystem } from '@/views/Projects/components/ProjectFormDialog/utils/convertFilSystemTreeToServerFileSystem';
import { removePdfExtension } from '@/views/Projects/components/ProjectFormDialog/utils/removePdfExtension';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { useEmptyDocumentProject } from '@/views/Project/hooks/useEmptyDocumentProject';
import { PROJECT_ROUTER_IDS, toProjectHomepage, toProjectPages } from '@/services/linker';
import { convertMarkdownToLexicalState } from '@/containers/PagesEditor/utils/convertMarkdownToLexicalState';
import { DriveFile, OneDriveFile, TreeFileSystemNode } from '@/types';
import useRouteId from '@/hooks/useRouteId';

type Params = {
  getOrCreateProject: () => Promise<ProjectFull>;
  getFormValues: <T extends keyof ProjectFormValue = keyof ProjectFormValue>(field: T) => ProjectFormValue[T];
  uploadingFilesPromisesRef: MutableRefObject<Promise<unknown>[]>;
  onClose: () => void;
};

type RenameParams = {
  id: string;
  name: string;
};

export const useFileSystemTree = ({ getOrCreateProject, getFormValues, onClose, uploadingFilesPromisesRef }: Params) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation('projectUpdate');
  const { showConfirmDialog } = useConfirmDialog();
  const { setForceDropZoneView } = useEmptyDocumentProject();
  const currentRouterId = useRouteId();
  const [searchParams, setSearchParams] = useSearchParams();

  const treeNodesByIdRef = useRef<Record<string, TreeFileSystemNode>>({});
  const [fileSystemRootNodes, setFileSystemRootNodes] = useState<TreeFileSystemNode[]>([]);
  const [documents, setDocuments] = useState<DocumentMetadata[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<DocumentMetadata | null>(null);
  const [selectedDocumentVersions, setSelectedDocumentVersions] = useState<DocumentVersionNode[] | undefined>(undefined);

  const createFolder = useCreateFolderHook();
  const deleteFolder = useDeleteFolderHook();
  const deleteFile = useDeleteFileHook();
  const uploadDocument = useUploadFileWithVdbHook();
  const updateFileSystem = useUpdateFilesystemBatchHook();
  const deleteDocuments = useDeleteDocuments(queryClient);
  const renameDocument = useRenameDocument(queryClient);
  const convertToPage = useConvertPdfToPagesHook();
  const createPage = useAutomatePageHook();
  const importMicrosoftFile = useImportMicrosoftFileHook();
  const importGoogleFile = useImportDocumentHook();
  const importAutodeskFile = useImportAutodeskFileHook();

  const loadFileSystem = async (project?: ProjectFull) => {
    setDocuments(project?.documents ?? []);
    updateFileSystemTree(project?.filesystem ?? { root: {} }, project?.documents ?? []);
  };

  const getRelatedFiles = (nodesById: Record<string, TreeFileSystemNode>, folderIds: string[]) => {
    const fileIds: string[] = [];

    let iterationFolderIds = [...folderIds];
    while (iterationFolderIds.length) {
      const nextFolderIds: string[] = [];
      Object.entries(nodesById).forEach(([, node]) => {
        if (!node.parentId || !iterationFolderIds.includes(node.parentId)) return;

        if (node.type === 'file') {
          fileIds.push(node.id);
        } else {
          nextFolderIds.push(node.id);
        }
      });
      iterationFolderIds = nextFolderIds;
    }

    return fileIds;
  };

  const setTreeNodeByIdState = (nodesById: Record<string, TreeFileSystemNode>) => {
    // Select only root nodes the rest should be inside in nodes children prop.
    const rootNodes = Object.entries(nodesById).reduce<TreeFileSystemNode[]>(
      (acc, [, node]) => (node.parentId ? acc : [...acc, node]),
      [],
    );

    Object.entries(nodesById).forEach(([, node]) => {
      if (!node.children) return;
      node.children = orderBy(node.children, ['type', 'order'], ['desc', 'asc']);
    });

    setFileSystemRootNodes(orderBy(rootNodes, ['type', 'order'], ['desc', 'asc']));
  };

  const updateFileSystemTree = (fileSystem: FileSystem, initialDocuments: DocumentMetadata[] = documents) => {
    const documentsById = initialDocuments.reduce<Record<string, DocumentMetadata>>((acc, document) => {
      acc[document._id!] = document;
      return acc;
    }, {});

    treeNodesByIdRef.current = constructFileSystemTree(fileSystem, documentsById);
    setTreeNodeByIdState(treeNodesByIdRef.current);
  };

  function createDocumentNodesFromFiles<T extends File | DriveFile>(files: T[], parentId?: string): TreeFileSystemNode<T>[] {
    const nodes = files.map(file => ({
      id: crypto.randomUUID(),
      type: 'file' as const,
      name: file.name!,
      order: 0,
      parentId,
      file,
      status: STATUS.LOADING,
      progress: 0,
    }));

    nodes.forEach(node => (treeNodesByIdRef.current[node.id] = node));
    if (parentId && treeNodesByIdRef.current[parentId]?.children) {
      treeNodesByIdRef.current[parentId].children.push(...nodes);
    }
    setTreeNodeByIdState(treeNodesByIdRef.current);

    return nodes;
  }

  const addDocumentIntoTree = ({
    id,
    parentId,
    projectSlug,
    document,
  }: {
    id: string;
    parentId?: string;
    projectSlug: string;
    document: DocumentMetadata;
  }) => {
    updateProjectCache({ queryClient, projectSlug }, prevProject => ({
      ...prevProject!,
      documents: [...(prevProject!.documents || []), document],
    }));
    setDocuments(prevDocuments => [...prevDocuments, document]);

    delete treeNodesByIdRef.current[id];
    treeNodesByIdRef.current[document._id!] = {
      type: 'file' as const,
      id: document._id!,
      name: removePdfExtension(document.filename),
      order: 0,
      file: undefined,
      document,
      parentId,
      status: STATUS.LOADED,
      progress: 100,
    };
    if (parentId && treeNodesByIdRef.current[parentId]?.children) {
      treeNodesByIdRef.current[parentId].children = treeNodesByIdRef.current[parentId].children.filter(child => child.id !== id);
      treeNodesByIdRef.current[parentId].children.push(treeNodesByIdRef.current[document._id!]);
    }
    setTreeNodeByIdState(treeNodesByIdRef.current);
  };

  async function uploadIntoVersions<T extends File | DriveFile>(
    files: T[],
    {
      importFn,
      versionedFileId,
    }: { importFn: (slug: string, node: DocumentVersionNode<T>) => Promise<DocumentMetadata>; versionedFileId: string },
  ) {
    const [file] = files;
    const nextVersionNode = {
      id: crypto.randomUUID(),
      lastModified: format(Date.now(), 'P'),
      file,
      progress: 0,
      status: STATUS.LOADING,
    } satisfies DocumentVersionNode<T>;
    setSelectedDocumentVersions(prevVersionsNodes => [nextVersionNode, ...(prevVersionsNodes ?? [])]);

    const currentProject = await getOrCreateProject();

    try {
      const uploadedVersion = await importFn(currentProject.slug, nextVersionNode);
      const nextVersion = { id: uploadedVersion._id!, last_modified: uploadedVersion.last_modified!, version_number: 500 };
      setSelectedDocumentVersions(
        prevVersionsNodes =>
          prevVersionsNodes?.map(prevVersionNode =>
            prevVersionNode.id === nextVersionNode.id
              ? {
                  id: uploadedVersion._id!,
                  lastModified: uploadedVersion.last_modified!,
                  version: nextVersion,
                  file: undefined,
                  progress: 100,
                  status: STATUS.LOADED,
                }
              : prevVersionNode,
          ),
      );
      treeNodesByIdRef.current[versionedFileId].versions?.push(nextVersion);
    } catch (error) {
      setSelectedDocumentVersions(
        prevVersionsNodes =>
          prevVersionsNodes?.map(prevVersionNode =>
            prevVersionNode.id === nextVersionNode.id ? { ...prevVersionNode, status: STATUS.ERROR } : prevVersionNode,
          ),
      );
      console.error('Error while uploading document version', error);
    }
  }

  async function uploadIntoTree<T extends File | DriveFile>(
    files: T[],
    {
      importFn,
      parentId,
    }: { importFn: (slug: string, node: TreeFileSystemNode<T>) => Promise<DocumentMetadata>; parentId?: string },
  ) {
    const currentProject = await getOrCreateProject();
    const newDocumentNodes = createDocumentNodesFromFiles(files, parentId);

    newDocumentNodes.map(async node => {
      const uploadPromise = importFn(currentProject.slug, node);
      uploadingFilesPromisesRef.current.push(uploadPromise);

      try {
        const document = await uploadPromise;
        addDocumentIntoTree({ id: node.id, projectSlug: currentProject.slug, parentId, document: document as DocumentMetadata });
      } catch (error) {
        treeNodesByIdRef.current[node.id].status = STATUS.ERROR;
        setTreeNodeByIdState(treeNodesByIdRef.current);
      } finally {
        uploadingFilesPromisesRef.current = uploadingFilesPromisesRef.current.filter(promise => promise !== uploadPromise);
      }
    });
  }

  const importOneDriveFiles = async (files: OneDriveFile[], { versionedFileId }: ImportParams) => {
    if (versionedFileId) {
      return uploadIntoVersions(files, {
        versionedFileId,
        importFn: (slug, node) =>
          importMicrosoftFile(
            slug,
            {
              file_url: node.file['@microsoft.graph.downloadUrl'],
              file_name: node.file.name,
            },
            { versioned_file_id: versionedFileId },
          ) as Promise<DocumentMetadata>,
      });
    }

    return uploadIntoTree(files, {
      importFn: (slug, node) =>
        importMicrosoftFile(slug, {
          file_url: node.file['@microsoft.graph.downloadUrl'],
          file_name: node.file.name,
        }) as Promise<DocumentMetadata>,
    });
  };

  const importGoogleFiles = async (files: google.picker.DocumentObject[], { versionedFileId }: ImportParams) => {
    if (versionedFileId) {
      return uploadIntoVersions(files, {
        versionedFileId,
        importFn: (slug, node) =>
          importGoogleFile(
            slug,
            {
              file_id: node.file.id,
              file_name: node.file.name!,
              mime_type: node.file.mimeType!,
            },
            { versioned_file_id: versionedFileId },
          ) as Promise<DocumentMetadata>,
      });
    }

    return uploadIntoTree(files, {
      importFn: (slug, node) =>
        importGoogleFile(slug, {
          file_id: node.file.id,
          file_name: node.file.name!,
          mime_type: node.file.mimeType!,
        }) as Promise<DocumentMetadata>,
    });
  };

  const importAutodeskFiles = async (files: AutodeskFile[], { versionedFileId }: ImportParams) => {
    if (versionedFileId) {
      return uploadIntoVersions(files, {
        versionedFileId,
        importFn: (slug, node) =>
          importAutodeskFile(slug, node.file, { versioned_file_id: versionedFileId }) as Promise<DocumentMetadata>,
      });
    }

    return uploadIntoTree(files, {
      importFn: (slug, node) => importAutodeskFile(slug, node.file) as Promise<DocumentMetadata>,
    });
  };

  const importDriveFiles: ImportDriveFilesFn = async (files, { type, versionedFileId }) => {
    if (type === 'onedrive') {
      return await importOneDriveFiles(files as OneDriveFile[], { versionedFileId });
    }
    if (type === 'google') {
      return await importGoogleFiles(files as google.picker.DocumentObject[], { versionedFileId });
    }
    if (type === 'autodesk') {
      return await importAutodeskFiles(files as AutodeskFile[], { versionedFileId });
    }
  };

  const uploadVersion = async (files: File[]) => {
    if (!selectedDocument) return;

    return uploadIntoVersions(files, {
      versionedFileId: selectedDocument._id!,
      importFn: (slug, node) =>
        uploadDocument(
          slug,
          { file: node.file },
          { versioned_file_id: selectedDocument._id! },
          {
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / (total || 0));

              setSelectedDocumentVersions(
                prevVersionsNodes =>
                  prevVersionsNodes?.map(prevVersionNode =>
                    prevVersionNode.id === node.id ? { ...prevVersionNode, progress: percentCompleted } : prevVersionNode,
                  ),
              );
            },
          },
        ),
    });
  };

  const uploadFiles = async (files: File[], parentId?: string) =>
    uploadIntoTree(files, {
      parentId,
      importFn: (slug, node) =>
        uploadDocument(
          slug,
          { file: node.file },
          { parentId, order: node.order },
          {
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / (total || 0));

              if (treeNodesByIdRef.current[node.id]) {
                treeNodesByIdRef.current[node.id].progress = percentCompleted;
                setTreeNodeByIdState(treeNodesByIdRef.current);
              }
            },
          },
        ),
    });

  const onFolderCreate: CreateHandler<TreeFileSystemNode> = async ({ parentId }) => {
    const currentProject = await getOrCreateProject();

    const tempId = crypto.randomUUID();
    const name = t('uploadFiles.newFolder');
    const folderNode = {
      type: 'folder' as const,
      id: tempId,
      name,
      file: undefined,
      order: 0,
      parentId: parentId ?? undefined,
      status: STATUS.LOADING,
      progress: 0,
      children: [],
    };

    treeNodesByIdRef.current![tempId] = folderNode;
    if (parentId && treeNodesByIdRef.current![parentId].children) {
      treeNodesByIdRef.current[parentId].children.push(folderNode);
    }
    setTreeNodeByIdState(treeNodesByIdRef.current);

    const newFolder = await createFolder(currentProject.slug, {
      name,
      order: 0,
      parentId: parentId ?? 'root',
    });

    if (!newFolder.id) return null;

    delete treeNodesByIdRef.current![tempId];
    treeNodesByIdRef.current[newFolder.id] = {
      type: 'folder' as const,
      id: newFolder.id,
      name: newFolder.name,
      order: newFolder.order ?? 0,
      parentId: parentId ?? undefined,
      status: STATUS.LOADED,
      file: undefined,
      progress: 0,
      children: [],
    };
    if (parentId && treeNodesByIdRef.current[parentId].children) {
      treeNodesByIdRef.current[parentId].children.push(treeNodesByIdRef.current[newFolder.id]);
      treeNodesByIdRef.current[parentId].children = treeNodesByIdRef.current[parentId].children.filter(({ id }) => id !== tempId);
    }

    setTreeNodeByIdState(treeNodesByIdRef.current);

    return { id: newFolder.id! };
  };

  const onDelete: DeleteHandler<TreeFileSystemNode> = async ({ nodes }) => {
    const node = nodes[0];
    const slug = getFormValues('slug');
    const result = await showConfirmDialog({
      title: node.data.type === 'folder' ? t('uploadFiles.confirmDelete.folderTitle') : t('uploadFiles.confirmDelete.fileTitle'),
      confirm: t('uploadFiles.confirmDelete.confirm'),
      cancel: t('uploadFiles.confirmDelete.cancel'),
    });
    if (!result || !slug) return;

    const fileIds = node.data.type === 'folder' ? getRelatedFiles(treeNodesByIdRef.current, [node.id]) : [node.id];

    [node.id, ...fileIds].forEach(id => {
      if (!treeNodesByIdRef.current[id]) return;
      treeNodesByIdRef.current[id].status = STATUS.DELETING;
    });
    setTreeNodeByIdState(treeNodesByIdRef.current);

    if (fileIds.length) {
      await deleteDocuments({ documentIds: fileIds, projectSlug: slug, updateUrl: true });
    }
    const fileSystem =
      node.data.type === 'folder'
        ? await deleteFolder(slug, { folder_id: node.id })
        : await deleteFile(slug, { file_id: node.id });

    updateProjectCache({ queryClient, projectSlug: slug }, prevProject => ({
      ...prevProject!,
      documents: (prevProject!.documents || []).filter(document => !fileIds.includes(document._id!)),
    }));
    setDocuments(prevDocuments => prevDocuments.filter(doc => !fileIds.includes(doc._id!)));
    updateFileSystemTree(fileSystem);
  };

  const onMove: MoveHandler<TreeFileSystemNode> = ({ dragIds, parentId, parentNode, index }) => {
    const slug = getFormValues('slug');
    if (!slug) return;

    let nextFileSystemRootNodes = [...fileSystemRootNodes];
    let nextParentChildren = parentNode?.data.children ?? nextFileSystemRootNodes;
    const insertPosition = index === 0 ? 'first' : index === nextParentChildren.length ? 'last' : 'after';
    const elementIdToInsertAfter = insertPosition === 'after' ? nextParentChildren[index]?.id : undefined;

    dragIds
      .reverse()
      .map(dragId => treeNodesByIdRef.current[dragId])
      .forEach(node => {
        const prevParentId = node.parentId;
        const isPutInSameFolder = prevParentId === (parentId ?? undefined);
        node.parentId = parentId ?? undefined;

        if (prevParentId && treeNodesByIdRef.current[prevParentId].children) {
          treeNodesByIdRef.current[prevParentId].children = treeNodesByIdRef.current[prevParentId]!.children!.filter(
            child => child.id !== node.id,
          );
          treeNodesByIdRef.current[prevParentId].children.forEach((child, i) => (child.order = i));
          if (isPutInSameFolder) nextParentChildren = treeNodesByIdRef.current[prevParentId].children;
        } else {
          nextFileSystemRootNodes = nextFileSystemRootNodes.filter(child => child.id !== node.id);
          if (isPutInSameFolder) nextParentChildren = nextFileSystemRootNodes;
        }

        const indexToInsert =
          insertPosition === 'first'
            ? 0
            : insertPosition === 'last'
              ? nextParentChildren.length
              : nextParentChildren.findIndex(child => child.id === elementIdToInsertAfter);
        nextParentChildren.splice(indexToInsert, 0, node);
      });

    nextFileSystemRootNodes.forEach((node, i) => (node.order = i));
    nextParentChildren.forEach((node, i) => (node.order = i));
    setTreeNodeByIdState(treeNodesByIdRef.current);

    const nextFileSystem = convertFilSystemTreeToServerFileSystem(treeNodesByIdRef.current);
    updateFileSystem(slug, nextFileSystem);
  };

  const renameFile = async ({ id, name }: RenameParams) => {
    const slug = getFormValues('slug');
    if (!slug) return;

    try {
      treeNodesByIdRef.current[id] = {
        ...treeNodesByIdRef.current[id],
        name,
        status: STATUS.LOADING,
      };
      setTreeNodeByIdState(treeNodesByIdRef.current);

      const updatedDocument = await renameDocument({ projectSlug: slug, documentId: id, name: `${name}.pdf` });

      setDocuments(prevDocuments =>
        prevDocuments.map(prevDocument =>
          id === prevDocument._id ? { ...prevDocument, document: updatedDocument } : prevDocument,
        ),
      );
      treeNodesByIdRef.current[id] = {
        ...treeNodesByIdRef.current[id],
        name: removePdfExtension(updatedDocument.filename),
        document: updatedDocument,
        status: STATUS.LOADED,
      };
      setTreeNodeByIdState(treeNodesByIdRef.current);
    } catch (error) {
      enqueueSnackbar(t('uploadFiles.updateNameToasts.failed'), { variant: 'error' });
      console.error('Error while renaming document', error);
    }
  };

  const renameFolder = async ({ id, name }: RenameParams) => {
    const slug = getFormValues('slug');
    if (!slug) return;

    const folder = treeNodesByIdRef.current[id];
    folder.name = name;
    setTreeNodeByIdState(treeNodesByIdRef.current);

    updateFileSystem(slug, convertFilSystemTreeToServerFileSystem({ [id]: folder }));
  };

  const onRename: RenameHandler<TreeFileSystemNode> = ({ id, name, node }) => {
    if (node.data.name === name) return;

    if (node.data.type === 'folder') {
      renameFolder({ id, name });
    } else {
      renameFile({ id, name });
    }
  };

  const onFileOpen = useCallback(
    (documentId: string) => {
      const slug = getFormValues('slug');
      if (!slug) return;

      setForceDropZoneView(false);

      if (PROJECT_ROUTER_IDS.includes(currentRouterId)) {
        setSearchParams(prevParams => {
          const nextParams = new URLSearchParams(prevParams);
          nextParams.set('documentId', documentId);
          return nextParams;
        });
      } else {
        navigate(toProjectHomepage({ projectSlug: slug, documentId }));
      }
    },
    [currentRouterId],
  );

  const onDocumentConvertToPage = useCallback(
    async (documentId: string) => {
      const slug = getFormValues('slug');
      if (!slug) return;

      const result = await showConfirmDialog({
        title: t('uploadFiles.convert.confirm.title'),
        description: (
          <Trans
            i18nKey="uploadFiles.convert.confirm.description"
            components={{ strong: <Box component="strong" sx={{ fontWeight: 'fontWeightMedium' }} /> }}
          >
            {t('uploadFiles.convert.confirm.description')}
          </Trans>
        ),
      });
      if (!result) return;

      try {
        const { name, markdown } = await convertToPage(slug, { document_id: documentId });
        const lexicalState = await convertMarkdownToLexicalState(markdown ?? '');

        const page = await createPage(slug, {
          content: lexicalState.root.children,
          markdown,
          name,
          page_type: PageType.page,
          parentId: 'root',
        });

        const currentDocumentId = searchParams.get('documentId') ?? undefined;
        navigate(toProjectPages({ projectSlug: slug, documentId: currentDocumentId, pageId: page._id }));
        onClose();
      } catch (error) {
        console.error('An error occurred while converting file to a page');
        enqueueSnackbar(t('uploadFiles.convert.error'), { variant: 'error' });
      }
    },
    [onClose],
  );

  const onAddVersion = useCallback((node: NodeApi<TreeFileSystemNode>) => {
    if (!node.data.document || !treeNodesByIdRef.current) return;
    setSelectedDocument(node.data.document);

    const versions = treeNodesByIdRef.current[node.data.document._id!]?.versions ?? [];
    setSelectedDocumentVersions(
      versions.map(version => ({
        id: version.id,
        lastModified: version.last_modified!,
        version,
        file: undefined,
        status: STATUS.LOADED,
        progress: 100,
      })),
    );
  }, []);

  const onVersionsClose = () => {
    setSelectedDocument(null);
    setSelectedDocumentVersions(undefined);
  };

  return {
    selectedDocument,
    selectedDocumentVersions,
    loadFileSystem,
    setDocuments,
    treeNodesByIdRef,
    fileSystemNodes: fileSystemRootNodes,
    importDriveFiles,
    uploadFiles,
    uploadVersion,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onFileOpen,
    onAddVersion,
    onVersionsClose,
    onDocumentConvertToPage,
  };
};
