import { alpha, Box, ButtonBase, ButtonBaseProps, Stack, Typography, useTheme } from '@mui/material';
import { ICON_TYPE } from '@/views/Projects/Documentations/iconType';
import Icon, { IconNames } from '@/components/Icon/Icon';
import UserAvatar from '@/components/Avatar/UserAvatar';
import { format } from 'date-fns/format';
import { formatDistance } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { ActionProps, ActionType, MainPageAction } from '@/api/generated';
import { FC } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { CARD_HEIGHT, CARD_WIDTH } from '@/views/Projects/Documentations/constants';

interface RecentlyViewedItemProps extends ButtonBaseProps {
  recentlyViewedItem: MainPageAction;
  actionTypeParams: (slug: string, documentId?: string) => { [key in ActionType]: (props: ActionProps) => void };
}

const RecentlyViewedItem: FC<RecentlyViewedItemProps> = ({ recentlyViewedItem, actionTypeParams, ...props }) => {
  const { t } = useTranslation('projects');
  const { palette, spacing } = useTheme();
  const { currentUser } = useAuth();
  const { display_text, action_props, type, slug, sub_title, time_stamp, location } = recentlyViewedItem;

  const commonStyles = {
    textAlign: 'left',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    breakWord: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
  };

  return (
    <ButtonBase
      onClick={() => actionTypeParams(slug)[type](action_props)}
      disableRipple
      sx={{
        display: 'inline-flex !important',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        gap: 1,
        minWidth: CARD_WIDTH,
        minHeight: CARD_HEIGHT,
        height: '100%',
        fontSize: 'body2.fontSize',
        color: palette.text.primary,
        boxShadow: 2,
        borderRadius: 2,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: alpha(palette.grey[50], 0.5),
        },
      }}
      {...props}
    >
      <Stack flexDirection="row" alignItems="center" sx={{ width: '100%', gap: 1, p: 1 }}>
        <Stack
          sx={{
            borderRadius: 99999,
            p: 1.25,
            width: 32,
            height: 32,
            boxShadow: 15,
            backgroundColor: palette.background.default,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {ICON_TYPE[type] && <Icon name={ICON_TYPE[type] as IconNames} fontSize="small" />}
        </Stack>
        <Typography
          sx={{
            fontWeight: 'fontWeightMedium',
            textAlign: 'left',
            fontSize: 'body3.fontSize',
            backgroundImage: `linear-gradient(90deg, ${palette.purple.dark} 0%, ${palette.purple.main} 36%, ${palette.high.dark} 100%)`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          }}
        >
          {t(`documentations.recentlyViewed.actionsName.${type}`)}
        </Typography>
      </Stack>
      <Stack
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
        sx={{ p: spacing(0.5, 1.5, 1), gap: 1.25, flex: 1 }}
      >
        {sub_title && (
          <Typography
            sx={{
              maxWidth: '100%',
              fontSize: 'body4.fontSize',
              color: palette.grey[800],
              ...commonStyles,
            }}
          >
            {sub_title}
          </Typography>
        )}
        <Typography
          sx={{
            fontWeight: 'fontWeightMedium',
            fontSize: 'body2.fontSize',
            color: palette.text.primary,
            ...commonStyles,
          }}
        >
          {display_text}
        </Typography>
        {location && (
          <Typography
            sx={{
              fontSize: 'body4.fontSize',
              color: palette.grey[800],
              ...commonStyles,
            }}
          >
            <Trans shouldUnescape>
              {t('list.address', {
                number: location.number,
                street: location.street,
                city: location.city,
                zip: location.zip,
                state: location.state,
              })}
            </Trans>
          </Typography>
        )}
        <Stack flexDirection="row" alignItems="center" gap={0.5} alignSelf="flex-start" mt="auto">
          <UserAvatar initialsSx={{ fontSize: 'body4.fontSize' }} size={16} user={currentUser} />
          <Box
            component="time"
            sx={{
              color: palette.grey[700],
              fontSize: 'body4.fontSize',
              fontWeight: 'fontWeightRegular',
              lineHeight: 'normal',
            }}
            dateTime={format(new Date(`${time_stamp}Z`), 'yyyy-MM-dd')}
          >
            {formatDistance(new Date(`${time_stamp}Z`), new Date(), { addSuffix: true })}
          </Box>
        </Stack>
      </Stack>
    </ButtonBase>
  );
};

export default RecentlyViewedItem;
