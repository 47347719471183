import { Components, Theme } from '@mui/material/styles';

const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    switchBase: ({ theme }) => ({
      '&.Mui-checked + .MuiSwitch-track': { backgroundColor: theme.palette.primary.dark },
      '&.Mui-checked .MuiSwitch-thumb': { color: theme.palette.primary.dark },
    }),
  },
  variants: [
    {
      props: { size: 'xsmall' },
      style: () => ({
        width: 20,
        height: 9,
        padding: 4,
        boxSizing: 'content-box',
        '.MuiSwitch-switchBase': {
          padding: 5,
          '&.Mui-checked': {
            transform: 'translateX(11px)',
            '.MuiSwitch-thumb': { color: '#fff' },
            '.MuiSwitch-track': { opacity: 1 },
          },
        },
        '.MuiSwitch-thumb': {
          width: 7,
          height: 7,
        },
      }),
    },
  ],
};
export default MuiSwitch;
