import { FC, ReactNode, useRef, useState } from 'react';
import { ChecklistSelectDialogContext, ChecklistSelectDialogContextValue } from '@/contexts/ChecklistSelectDialogContext';
import ChecklistSelectDialog from '@/components/ChecklistSelectDialog';
import { Checklist } from '@/api/generated';

interface ChecklistSelectDialogProviderProps {
  children: ReactNode;
}

export const ChecklistSelectDialogProvider: FC<ChecklistSelectDialogProviderProps> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [excludedIds, setExcludedIds] = useState<string[]>([]);
  const resolveRef = useRef<(result: Checklist | undefined) => void>(() => false);

  const contextValue: ChecklistSelectDialogContextValue = {
    showChecklistSelectDialog: (nextExcludedIds: string[] = []) => {
      setIsOpened(true);
      setExcludedIds(nextExcludedIds);
      return new Promise<Checklist | undefined>(resolve => (resolveRef.current = resolve));
    },
  };

  const onDialogClose = (result?: Checklist) => {
    resolveRef.current(result);
    setIsOpened(false);
    setExcludedIds([]);
  };

  return (
    <ChecklistSelectDialogContext.Provider value={contextValue}>
      <ChecklistSelectDialog isOpened={isOpened} excludedIds={excludedIds} onClose={onDialogClose} />
      {children}
    </ChecklistSelectDialogContext.Provider>
  );
};
