import { createContext } from 'react';

type EditorLoadingContextValue = {
  isDocumentEditorLoading: boolean;
  isCompareLoading: boolean;
  isCompareLeftLoading: boolean;
  isCompareRightLoading: boolean;
  loadingPercent: number;
};

export const EditorLoadingContext = createContext<EditorLoadingContextValue | null>(null);
